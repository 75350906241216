import React from 'react';
import App from 'next/app';
import * as Sentry from '@sentry/browser';
import Head from 'next/head';

import { Layout } from '~/components/layout';
import { Container } from '~/components/styles/container';

import '../fonts.css';

Sentry.init({
  dsn: process.env.SENTRY,
  environment: process.env.NODE_ENV,
  release: `appfollow-dashboard@${process.env.VERSION}_${process.env.BUILD_ID}`,
  enabled: process.env.NODE_ENV === 'production',
});

class MyApp extends App {
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  public render() {
    const { Component, pageProps } = this.props;

    return (
      <Layout>
        <Head>
          <title>App Reviews</title>
        </Head>
        <Container
          css={{
            minHeight: 'inherit',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Component {...pageProps} />
        </Container>
      </Layout>
    );
  }
}

export default MyApp;
